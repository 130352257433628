import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../styles/utilities/settings';
import PageLink from '../../helpers/PageLink';
import Wrapper from '../../../styles/utilities/Wrapper';

import { above, below } from '../../../styles/utilities/mediaQueries';
import { button } from '../../../styles/utilities/elements';
import ImageLoader from '../../helpers/ImageLoader';

const ImageFeature = ({
  content: {
    flipImageSide,
    imageWText: { heading, copy, image, link },
  },
}) => (
  <ScImageFeature flip={flipImageSide}>
    <Wrapper width={970}>
      <div className="row">
        <div className="image">
          <ImageLoader src={image} alt={heading} />
        </div>
        <div className="content">
          {heading && <h3>{heading}</h3>}
          {copy && <BlockContent blocks={copy} />}
          {link && link.copy && (
            <PageLink
              style={{
                marginTop: '20px',
              }}
              content={link}
            />
          )}
        </div>
      </div>
    </Wrapper>
  </ScImageFeature>
);

export default ImageFeature;

export const ScImageFeature = styled.section`
  margin: 70px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    margin: 100px 0;
  }

  ${Wrapper} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 0 40px;
    }
  }

  .row {
    ${above.ipadPort`
      display: flex;
      align-items: center;
      flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
    `}

    &:nth-child(2n) {
      flex-direction: ${({ flip }) => (flip ? 'row' : 'row-reverse')};

      .image {
        ${above.ipadPort`
        margin-right: 0px;
        margin-left: 70px;
      `}
      }
    }

    .image {
      align-items: stretch;

      ${above.ipadPort`
        width: calc(50% - 35px);
        margin: ${({ flip }) => (flip ? '0 0 0 auto' : '0 auto 0 0')}
      `}

      ${below.ipadPort`
        margin: 0 auto 40px;
      `}
    }

    .content {
      padding: 0 0;

      ${above.ipadPort`
        max-width: ${breakpoints.pageWidth / 2}px;
        width: calc(50% - 35px);
      `}

      ${below.ipadPort`
        padding: 0;
      `}

      > a {
        ${button};
      }

      h3 {
        font-size: 30px;
        color: ${colors.blue};
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        line-height: 1.6;
      }

      > div {
        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
