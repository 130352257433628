import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { breakpoints, colors } from '../../../styles/utilities/settings';
import Wrapper from '../../../styles/utilities/Wrapper';
import ImageLoader from '../../helpers/ImageLoader';

const LogoSalad = ({ content: { footerMessage, headingMessage, logos } }) => (
  <ScLogoSalad>
    <Wrapper width={700}>
      {headingMessage && <h2 className="heading">{headingMessage}</h2>}
      {logos.map((logo) => (
        <div key={logo._ket}>
          <a href={logo.url} target="_blank" rel="noopener noreferrer">
            <div className="logo">
              {logo.logoImage && <ImageLoader src={logo.logoImage} />}
            </div>
            <div className="copy">
              <h3>{logo.title}</h3>
              <BlockContent blocks={logo.message} />
            </div>
          </a>
        </div>
      ))}
      {footerMessage && <p className="footer">{footerMessage}</p>}
    </Wrapper>
  </ScLogoSalad>
);

export default LogoSalad;

export const ScLogoSalad = styled.section`
  margin: 70px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    margin: 200px 0 100px;
  }

  ${Wrapper} {
    > div {
      + div {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 1px solid ${colors.lineGray};
      }
    }
  }

  h3 {
    margin: 0 0 10px;
    font-size: 30px;
    color: ${colors.black};
  }

  p {
    color: ${colors.black};

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;

    @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: ${breakpoints.ipadPort}px) {
      text-align: center;
      max-width: 400px;
      display: block;
      margin: 0 auto;
    }

    &:hover {
      h3,
      p {
        color: ${colors.blue};
      }
    }

    .logo {
      width: 200px;

      @media screen and (max-width: ${breakpoints.ipadPort}px) {
        margin: 0 auto 30px;
        box-shadow: 0 0 20px -16px ${colors.black};
        padding: 10px;
      }
    }

    .copy {
      @media screen and (min-width: ${breakpoints.ipadPort + 1}px) {
        width: calc(100% - 250px);
      }
    }
  }
  .footer {
    font-size: 18px;
    margin-top: 100px;
  }
`;
