import React from 'react';
import ImageFeature from './flexibleContent/story/ImageFeature';
import CopyBanner from './flexibleContent/story/CopyBanner';
import LogoSalad from './flexibleContent/story/LogoSalad';
import ImageGallery from './flexibleContent/ImageGallery';
import RichText from './flexibleContent/RichText';
import SmallCenteredLogo from './flexibleContent/SmallCenteredLogo';
import VideoImage from './flexibleContent/VideoImage';
import LargeImage from './flexibleContent/LargeImage';
// import DataTable from './flexibleContent/DataTable';
import CallToActionBanner from './flexibleContent/CallToActionBanner';
import ImageFeatures from './flexibleContent/ImageFeatures';
import ContactForm from './flexibleContent/ContactForm';
import IconFeatures from './flexibleContent/IconFeatures';
import HeadingSection from './flexibleContent/HeadingSection';
import DonationBox from './flexibleContent/DonationBox';
import Interupter from './flexibleContent/Interupter';
import ShopHighlight from './flexibleContent/ShopHighlight';
import ProgramsList from './flexibleContent/ProgramsList';
import EventsList from './flexibleContent/EventsList';
import CampaignsList from './flexibleContent/CampaignsList';
import VimeoEmbed from './flexibleContent/VimeoEmbed';

const FlexibleContentLoop = ({ contentArray }) =>
  contentArray &&
  contentArray.map((content) => (
    <React.Fragment key={content._key}>
      {content._type === 'copyBanner' && <CopyBanner content={content} />}
      {content._type === 'imageGallery' && <ImageGallery content={content} />}
      {content._type === 'imageFeature' && <ImageFeature content={content} />}
      {content._type === 'logoSalad' && <LogoSalad content={content} />}
      {content._type === 'richText' && <RichText content={content} />}
      {content._type === 'smallCenteredLogo' && (
        <SmallCenteredLogo content={content} />
      )}
      {content._type === 'videoImage' && <VideoImage content={content} />}
      {content._type === 'largeImage' && <LargeImage content={content} />}
      {/* {content._type === 'dataTable' && <DataTable content={content} />} */}
      {content._type === 'callToActionBanner' && (
        <CallToActionBanner content={content} />
      )}
      {content._type === 'imageFeatures' && <ImageFeatures content={content} />}
      {content._type === 'contactForm' && <ContactForm content={content} />}
      {/* {content._type === 'locationsList' && <Locations content={content} />} */}
      {content._type === 'iconFeatures' && <IconFeatures content={content} />}
      {content._type === 'headingSection' && (
        <HeadingSection content={content} />
      )}
      {content._type === 'donationBox' && <DonationBox content={content} />}
      {content._type === 'interupter' && <Interupter content={content} />}
      {content._type === 'shopHighlight' && <ShopHighlight content={content} />}
      {content._type === 'programsList' && <ProgramsList content={content} />}
      {content._type === 'eventsList' && <EventsList content={content} />}
      {content._type === 'campaignsList' && <CampaignsList content={content} />}
      {content._type === 'vimeoEmbed' && <VimeoEmbed content={content} />}
    </React.Fragment>
  ));

export default FlexibleContentLoop;
