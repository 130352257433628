import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../styles/utilities/Wrapper';

import { breakpoints, colors } from '../../../styles/utilities/settings';
import SvgSelector from '../../SvgSelector';

const CopyBanner = ({ content }) => (
  <ScCopyBanner>
    <div className="icon">
      <SvgSelector name="paw" />
    </div>
    <Wrapper>
      <h2>{content.heading}</h2>
    </Wrapper>
  </ScCopyBanner>
);

export default CopyBanner;

export const ScCopyBanner = styled.section`
  position: relative;
  background-color: ${colors.blue};
  color: ${colors.white};
  text-align: center;
  margin: 100px 0 70px;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    margin: 120px 0 100px;
  }

  ${Wrapper} {
    padding: 120px 0 100px;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 70px 40px 50px;
    }
  }

  .icon {
    box-sizing: content-box;
    display: block;
    background-color: ${colors.yellow};
    border-radius: 100%;
    padding: 30px;
    height: 70px;
    width: 70px;
    z-index: 2;
    border: 7px solid ${colors.white};
    display: flex;
    justify-content: center;
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 20px;
      height: 70px;
      width: 70px;
      margin: 0 auto -65px;
    }

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      padding: 5px;
      height: 70px;
      width: 70px;
      margin: 0 auto -50px;
    }

    svg {
      width: 70px;
      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        width: 50px;
      }

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        width: 40px;
      }
    }
  }

  h2 {
    margin: 0;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      font-size: 40px;
    }
  }
`;
