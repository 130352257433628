import React, { Component } from 'react';
import IcomoonReact from 'icomoon-react/build/src/IcomoonReact';
import styled from 'styled-components';
import sanityClient from '@sanity/client';
import Layout, { SiteContext } from '../components/Layout';
import Dochead from '../components/Dochead';
import FlexibleContentLoop from '../components/FlexibleContentLoop';
import BannerContentLoop from '../components/BannerContentLoop';
import { colors } from '../styles/utilities/settings';
import iconSet from '../fonts/selection.json';
import StoryBanner from '../components/heroBanners/StoryBanner';
import StoryContentLoop from '../components/StoryContentLoop';
import StoryDonation from '../components/StoryDonation';

const { GATSBY_SANITY_ID } = process.env;
const { GATSBY_SANITY_DATASET } = process.env;
const { GATSBY_SANITY_TOKEN } = process.env;

class preview extends Component {
  state = {
    previewData: null,
    pageLoad: true,
  };

  componentDidMount() {
    const client = sanityClient({
      projectId: GATSBY_SANITY_ID,
      dataset: GATSBY_SANITY_DATASET,
      token: GATSBY_SANITY_TOKEN,
      useCdn: true,
    });

    if (global.window.location.search) {
      const query = `*[_id in path("${this.idReturn(
        global.window.location.search
      )}")]`;

      client.fetch(query).then((data) => {
        this.setState({ previewData: data[0], pageLoad: false });
      });
    }
  }

  idReturn(url) {
    const splitIDs = url.split('?docid=')[1];
    return splitIDs;
  }

  render() {
    const { pageLoad, previewData } = this.state;
    return (
      <Layout>
        <SiteContext.Consumer>
          {(value) => (
            <>
              <Dochead
                title="Preview"
                siteName={value.siteTitle}
                pageImage={null}
              />
              {pageLoad && !previewData && (
                <Loader>
                  <IcomoonReact
                    iconSet={iconSet}
                    color={colors.white}
                    size={100}
                    icon="loading"
                  />
                </Loader>
              )}
              {!pageLoad && previewData && (
                <>
                  {previewData._type === 'campaign' && (
                    <>
                      <StoryBanner
                        copy={{
                          heading: previewData.bannerHeading,
                          message: previewData.bannerMessage,
                        }}
                        image={previewData.mainImage}
                      />
                      <StoryContentLoop
                        contentArray={previewData.flexibleContent}
                      />
                      <StoryDonation
                        heading={previewData.donationMessage}
                        mainButton={previewData.donationlink}
                        secondaryButton={previewData.additionallink}
                      />
                    </>
                  )}
                  {previewData._type === 'page' && (
                    <>
                      <BannerContentLoop
                        pageTitle={previewData.title}
                        mainImage={previewData.mainImage}
                        contentArray={previewData.banners}
                      />
                      <FlexibleContentLoop
                        contentArray={previewData.flexibleContent}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </SiteContext.Consumer>
      </Layout>
    );
  }
}

export default preview;

export const Loader = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &:before {
    content: '';
    display: block;
    background-color: ${colors.blue};
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.96;
  }

  svg {
    animation: rotating 0.3s linear infinite;
  }
`;
