import React from 'react';
import styled from 'styled-components';
import BackgroundImage, { SBackgroundImage } from '../helpers/BackgroundImage';
import Wrapper from '../../styles/utilities/Wrapper';
import { colors, breakpoints } from '../../styles/utilities/settings';

const StoryBanner = ({ image, copy: { heading, message } }) => (
  <ScStoryBanner>
    <BackgroundImage src={image} alt={heading} width={1500}>
      <Wrapper width={970}>
        <div>
          <h1>{heading}</h1>
          {message && <p>{message}</p>}
        </div>
      </Wrapper>
    </BackgroundImage>
  </ScStoryBanner>
);

export default StoryBanner;

export const ScStoryBanner = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(1, 202, 254, 0.5) 0%,
      rgba(1, 202, 254, 0.5) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 66.666%;
      background: linear-gradient(
        90deg,
        rgba(1, 202, 254, 0.68) 0%,
        rgba(1, 101, 127, 0) 100%
      );
    }
  }

  h1 {
    font-size: 47px;
    margin-bottom: 0;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      max-width: 600px;
    }
  }

  ${SBackgroundImage} {
    display: flex;
    align-items: center;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      height: 70vh;
      max-height: 670px;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 70px 0;
    }
  }

  ${Wrapper} {
    z-index: 2;
    position: relative;
    color: ${colors.white};
    display: flex;
    width: 100%;
    justify-content: flex-start;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 0 40px;
    }

    > div {
    }
  }

  p {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 20px;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      max-width: 400px;
    }
  }
`;
