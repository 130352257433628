import React from 'react';
import styled from 'styled-components';
import Wrapper from '../styles/utilities/Wrapper';
import { colors, breakpoints } from '../styles/utilities/settings';
import { button } from '../styles/utilities/elements';

const StoryDonation = (props) => {
  const { heading, mainButton, secondaryButton } = props;

  return (
    <ScStoryDonation>
      {heading && (
        <Wrapper width={970}>
          <div>
            <h3>{heading}</h3>
            <div className={secondaryButton ? 'double-links' : 'single-link'}>
              {mainButton && (
                <a target="_blank" rel="noreferrer" href={mainButton.url}>
                  {mainButton.copy}
                </a>
              )}

              {secondaryButton && secondaryButton.copy && (
                <a target="_blank" rel="noreferrer" href={secondaryButton.url}>
                  {secondaryButton.copy}
                </a>
              )}
            </div>
          </div>
        </Wrapper>
      )}
    </ScStoryDonation>
  );
};

export default StoryDonation;

export const ScStoryDonation = styled.section`
  margin: 70px 0;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    margin: 100px 0;
  }

  ${Wrapper} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      padding: 0 40px;
    }
    > div {
      background-color: ${colors.blue};
      color: ${colors.white};
      padding: 80px 20px;

      h3 {
        text-align: center;
        max-width: 700px;
        margin: 0 auto;

        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          font-size: 40px;
        }
      }

      a {
        ${button}
        text-align: center;
        width: 90%;
        max-width: 350px;
        display: block;
        background-color: ${colors.black};
        margin: 50px auto 0;

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          display: block;
          text-align: center;
          margin: 30px auto 0;
        }

        &:hover {
          background-color: ${colors.white};
          color: ${colors.black};
        }
      }

      .double-links {
        display: flex;
        justify-content: center;

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          flex-wrap: wrap;
        }

        a {
          margin: 50px 10px 0;
          @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
            &:last-child {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
`;
